<template>
  <div>
    <a-page-header title="Полезные ресурсы" />
    
    <a-spin 
      :spinning="loading"
      size="large"
    >
      <div>
        <div class="listNews" v-if="resources">
          <article-card
            v-for="article in resources.data"
            :key="article.id"
            :article="article"
            :showCover="true"
          />
          <a-pagination 
            class="text-right"
            :total="resources.total"
            :showTotal="(total, range) => `${range[0]}-${range[1]} из ${total}`"
            v-model:current="currentPage"
            @change="loadResources"
          />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import articleCard from "@/components/sp-resource-card.vue";
import api from "@/api";
export default {
  name: "AllResources",
  components: { articleCard },
  setup() {
    const resources = ref(null);
    const currentPage = ref(1);
    const loading = ref(false);

    const loadResources = async(page) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      loading.value = true;
      try {
        resources.value = await api.usefulResources.getResources(page);
      }
      finally {
        loading.value = false;
      }
    };

    onMounted(async () => {
      await loadResources(currentPage.value);
    });
    return {
      resources,
      currentPage,
      loadResources,
      loading
    };
  },
};
</script>

<style lang="scss">
.ant-page-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;

  & .ant-page-header-heading-title {
    font-size: 2rem;
    font-weight: 700;
  }
}

.listNews {
  margin-bottom: 2rem;
  display: grid;
  grid-gap: 1rem;
}
</style>