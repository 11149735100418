<template>
  <router-link :to="{ name: 'UsefulResource',  params: { id: article.id }}" class="resourceCard">
    <div v-if="article.pictureURL && showCover" class="cover">
      <img :src="article.pictureURL"/>
    </div>
    <div class="main-block" :style="showCover ? 'width: 67%;' : 'width: 100%'">
      <div class="meta">
        <div class="title">{{ article.title }}</div>
      </div>    
      <div class="description">{{ article.previewText }}</div>
    </div>
  </router-link>
</template>

<script>
import moment from 'moment';

export default {
  name: 'sp-resource-card',
  props: {
    article: {
      type: Object,
      default: null
    },
    showCover: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      moment
    }
  }
}
</script>

<style lang="scss">
.resourceCard {
  border: 1px solid #eeeeee;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  &:hover, &:focus {
    color: inherit;
    box-shadow: 0px 0px 20px #E4E9F0;
  }
  & .cover {
    position: relative;
    height: 12rem;
    width: 30%;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & .main-block {
    padding: 2rem;
    
    & .description {
      font-size: 18px;
    }
    & .meta {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;
      
      & .title {
        width: calc(100% - 6rem);
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
        color: #2B2D5B;
      }

      & .date {
        text-align: right;
        color: rgba(43, 45, 91, 0.5);
        padding-top: 0.5rem;
        width: 6rem;
        font-size: 18px;
      }
    }
  }
}
</style>