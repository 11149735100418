<template>
  <div v-if="resource" class="singlePage">
    <div class="meta">
      <h1>
        {{ resource.title }}
      </h1>
    </div>
    <div class="content">
      <div v-html="resource.description"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import api from "@/api";
import { useRoute } from "vue-router";

export default {
  name: "SingleResouce",
  setup() {
    const resource = ref(null);
    const route = useRoute();

    onMounted(async () => {
      const id = route.params.id;
      resource.value = await api.usefulResources.getSingleResource(id);
    });
    return {
      resource,
    };
  },
};
</script>